import { Inter } from "next/font/google";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

type TextProps = DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  children: ReactNode;
};

const inter = Inter({ subsets: ["latin"] });

export const Text = ({ children, className, ...textProps }: TextProps) => {
  return (
    <p className={classNames(inter.className, className)} {...textProps}>
      {children}
    </p>
  );
};
