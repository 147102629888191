import { Logo } from "../Logo";
import Link from "next/link";
import { Inter } from "next/font/google";
import { LinkButton } from "../LinkButton";
import { getRoute, ReturnedRoute } from "~/utils/getRoute";
import { IconType } from "~/assets/icons/iconPaths";
import Icon from "~/assets/icons/Icon";
import { useSingleStoreAtom } from "~/atoms/singleStore";
import classNames from "classnames";

const inter = Inter({ subsets: ["latin"] });

const links: Array<FooterLinkProps & { id: number }> = [
  {
    id: 1,
    href: "https://info.butikdanmark.dk/bliv-medlem",
    name: "heartHand",
    text: "Åbn din egen butik",
    target: "_blank",
  },
  {
    id: 2,
    href: getRoute("/customer-service"),
    name: "messageSmile",
    text: "Kundeservice",
  },
];

function Footer() {
  const { isLimited } = useSingleStoreAtom();

  return (
    <footer className={inter.className}>
      {!isLimited && (
        <ul className="bg-primary text-white p-20 md:p-12 md:flex md:flex-wrap md:gap-x-12 md:gap-y-5 md:justify-center">
          {links.map(({ id, href, name, text }) => (
            <FooterLink key={id} href={href} name={name} text={text} />
          ))}
          <li>
            <LinkButton
              theme="ternary"
              target="_blank"
              className="py-3"
              href={getRoute("/backroom/login")}
            >
              <Icon name="lock" />
              Baglokalet
            </LinkButton>
          </li>
        </ul>
      )}
      <div
        className={classNames(
          "flex flex-col justify-center md:grid md:grid-cols-2 xl:grid-cols-3 items-center xl:flex-row xl:justify-between text-center p-12",
          isLimited && "border-t border-secondary-200"
        )}
      >
        <Logo openNewTab={isLimited} className="md:order-1 xl:order-none" />
        <p className="mt-7 mb-4 md:mb-16 xl:m-0 md:col-span-2 xl:col-span-1 text-sm sm:text-lg font-[500]">
          Butik Danmark — Den ægte vare ❤️
        </p>
        <div className="md:order-1 xl:order-none justify-self-end flex justify-center gap-3 flex-wrap sm:gap-10 mt-3 sm:mt-0 text-xs sm:text-sm">
          <Link
            className="text-secondary-500 hover:text-black"
            href="/privatlivsbetingelser"
          >
            Persondatapolitik
          </Link>
          <Link
            className="text-secondary-500 hover:text-black"
            href="/handelsbetingelser"
          >
            Handelsbetingelser
          </Link>
          <Link className="text-secondary-500 hover:text-black" href="/cookies">
            Cookies
          </Link>
        </div>
      </div>
    </footer>
  );
}

type FooterLinkProps = {
  href: ReturnedRoute | string;
  name: IconType;
  text: string;
  target?: string;
};
function FooterLink({ href, name, text, target }: FooterLinkProps) {
  return (
    <li>
      <LinkButton theme="link" target={target} className="py-3" href={href}>
        <Icon name={name} />
        {text}
      </LinkButton>
    </li>
  );
}

export { Footer };
